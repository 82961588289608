@font-face {
    font-family: 'HelveticaRegular';
    src: url('../../helvetica-webfont_latin.eot');
    src: url('../../helvetica-webfont_latin.eot?#iefix') format('embedded-opentype'),
        url('../../helvetica-webfont_latin.ttf') format('truetype'),
        url('../../helvetica-webfont_latin.svg#HelveticaRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaBold';
    src: url('../../helvetica_bold-webfont_latin.eot');
    src: url('../../helvetica_bold-webfont_latin.eot?#iefix') format('embedded-opentype'),
        url('../../helvetica_bold-webfont_latin.ttf') format('truetype'),
        url('../../helvetica_bold-webfont_latin.svg#HelveticaRegular') format('svg');
    font-weight: bold;
    font-style: normal;        
}

.navbar-brand{
    width:160px;
    background:url(../../../images/fasapay_logo_s.png) 10px no-repeat;
    /*text-indent:-1000000000px;*/
}

h1{
    color:#F7941C !important;
    font-weight:bold !important;
}

.sp-ang{
    margin-top:20px;
    margin-bottom:15px;
    padding-left:5px;
    padding-right:5px;
}
.sp-ang div.bg{
    height:35px;
    background-image: -webkit-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
    background-image:         linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
    border-radius:3px;
    border:1px solid #CCC;
}
.sp-ang a.img{
    position:relative;
    top:-15px;
    float:right;
}
.sp-ang div.text{
    margin-left:5px;
    font-size:10px;
    font-weight:bold;
    float:left;
}

/*===BAHASA===*/
ul.bahasa {
    margin-top: 15px;
    margin-bottom: 15px;
}

ul.bahasa li a{
    padding: 0;
    margin-left: 5px;
}


ul.bahasa .id{
    width:16px;
    height:16px;    
    background:url(../../../images/bahasa.gif) no-repeat -0px -0px !important;    
}
ul.bahasa .en{
    width:16px;
    height:16px;   
    background:url(../../../images/bahasa.gif) no-repeat -16px 0 !important;    
}


/*==== Footer ====*/
.footer{
    margin:0 0 0 0 !important;
    padding:25px 7px 30px 7px;
    background:#000;
    color:#aaa;
    text-align: left !important;
}
.footer a{
    font-weight:normal;
    font-size:12px;
    text-decoration:none;
    color:#CCC;
}
.footer a:hover{
    color:#FFF;
}

/* YII FORM COLOR */

div.form div.error label:first-child,
div.form label.error,
div.form span.error
{
    color: #C00;
}

div.form div.error input,
div.form div.error textarea,
div.form div.error select,
div.form input.error,
div.form textarea.error,
div.form select.error
{
    background: #FEE;
    border-color: #C00;
}

div.form div.success input,
div.form div.success textarea,
div.form div.success select,
div.form input.success,
div.form textarea.success,
div.form select.success
{
    background: #E6EFC2;
    border-color: #C6D880;
}

div.form .errorMessage
{
    color: #C00;
    font-size: 0.9em;
    clear:both;
}

div.form span.required{
    color: #C00;
}

/*BADGE COLOR*/
.badge {
    padding: 1px 9px 2px;
    font-size: 12.025px;
    font-weight: bold;
    white-space: nowrap;
    color: #ffffff;
    background-color: #999999;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
}
.badge:hover {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
}

.badge-error {
    background-color: #b94a48;
}
.badge-error:hover {
    background-color: #953b39;
}
.badge-warning {
    background-color: #f89406 ;
}
.badge-warning:hover {
    background-color: #c67605 ;
}
.badge-success {
    background-color: #468847 ;
}
.badge-success:hover {
    background-color: #356635;
}
.badge-info {
    background-color: #3a87ad ;
}
.badge-info:hover {
    background-color: #2d6987 ;
}
.badge-inverse {
    background-color: #333333 ;
}
.badge-inverse:hover {
    background-color: #1a1a1a;
}

/*=====menu=====*/
.glossymenu{
    margin: 5px 0 20px 0  !important;
    padding: 0 !important;
    /*width: 190px; width of menu*/
    border-bottom-width: 0 !important;
}

ul.glossymenu li{
    list-style-type:none !important;
}

.glossymenu .menuitem a{
    background:none !important;
    color: #000 !important;
    display: block !important;
    position: relative !important; /*To help in the anchoring of the ".statusicon" icon image*/
    width: 100% !important;
    padding: 7px 0 7px 10px !important;
    text-decoration: none !important;
    border-bottom:1px solid #e0e0e0 !important;
}


.glossymenu .menuitem a:visited, .glossymenu .menuitem:active{
    color: #000;
}

.glossymenu .menuitem a .statusicon{ /*CSS for icon image that gets dynamically added to headers*/
    position: absolute;
    top: 10px;
    right: 5px;
    border: none;
}

.glossymenu .menuitem a:hover{
    color:#666 !important;
}

.glossymenu ul.submenu{ /*DIV that contains each sub menu*/
    background: white  !important;
}

.glossymenu ul.submenu{ /*UL of each sub menu*/
    list-style-type: none  !important;
    margin: 0  !important;
    padding: 0  !important;
}

.glossymenu ul.submenu li{

}

.glossymenu ul.submenu li a{
    display: block  !important;
    font-style: normal !important;
    font-size: 13px !important;
    color: #666  !important;
    text-decoration: none  !important;
    padding: 4px 0 4px 25px  !important;
    border-bottom:1px dashed #CCC !important;
}

.glossymenu ul.submenu li a:hover{
    background: #e0e0e0  !important;
    color: #FFF  !important;
}
.glossymenu ul.submenu li a.menu-active{
    background: #f7941c  !important;
    color: #FFF  !important;
}

/* MENU */

#member_menu .panel{
    margin-bottom: 0;
}

.mainmenu{

}

.submenu{
    padding-left: 10px;
}

/* PANEL STYLE */
.panel-default > .panel-heading {
  background-image: -webkit-linear-gradient(top, #aac842 0%, #8cb400 100%) !important;
  background-image:         linear-gradient(to bottom, #aac842 0%, #8cb400 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0);
  background-repeat: repeat-x;
}
.panel-default{
	border-color:#aac842 !important;
}

/*----box notif----*/
.errorSummary{
	padding:5px;
	background:#f2dede;
	color: #a94442;
}
.errorSummary h1{
	font-size:14px !important;
	color: #a94442 !important;
}

.errorMessage{
	color: #a94442;
}



.blue{
	min-height:160px;
	padding:7px !important;
	background:#598DD1;
	color:#FFF;
	border-radius:4px;
}
.circle {
	background:#FFF;
	border-radius: 100%;
	height: 25px;
	width: 25px;
	display: block;
	text-align: center;
	float:left;
	margin-right: 10px;
}

.circle p {
	margin-top: 0.05em;
	font-size: 16px;
	font-weight: bold;
	font-family: sans-serif;
	color: #598DD1;
	vertical-align: central;        
}
.para {
	float:left;

}
h3 {
	margin-top:0;
}


/*member home*/
.blue{
	min-height:160px;
	padding:7px !important;
	background:#598DD1;
	color:#FFF;
	border-radius:4px;
}
.white{
	padding:7px !important;
	background:#FFF;
	color:#598DD1;
	border-radius:4px;
	font-weight:bold;
}
.circle {
	background:#FFF;
	border-radius: 100%;
	height: 25px;
	width: 25px;
	display: block;
	text-align: center;
	float:left;
	margin-right: 10px;
}

.circle p {
	margin-top: 0.05em;
	font-size: 16px;
	font-weight: bold;
	font-family: sans-serif;
	color: #598DD1;
	vertical-align: central;        
}
.para {
	float:left;

}
h3 {
	margin-top:0;
}

ul.two-column {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}

.box-shadow {
    margin: 30px auto 0 auto;
    background: white;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    padding: 20px;
    border: 1px solid #adaa9f;
    -moz-box-shadow: 0 2px 2px #9c9c9c;
    -webkit-box-shadow: 0 2px 2px #9c9c9c;
}

.text-wrap {
    overflow-wrap: anywhere;
}